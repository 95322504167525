import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../pages/service/account.service';
import { StorageService } from '../pages/service/storage.service';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html',
})
export class AppSidebarComponent {
    @ViewChild('menuContainer') menuContainer!: ElementRef;
    @Input() isMobile: boolean = false;
    idSociedad: number = 0;

    constructor(
        public layoutService: LayoutService,
        public el: ElementRef,
        private router: Router,
        private accountService: AccountService,
        public storageService: StorageService
    ) { }

    toggleMenuMode() {
        this.layoutService.toggleMenuMode();
    }

    get isSlimMode() {
        return this.layoutService.config().menuMode === 'slim';
    }

    pressLogout() {
        this.accountService.logout()
        this.router.navigateByUrl('/login');
    }

    ngOnInit() {
        const LSUser = localStorage.getItem("user");

        // si el usuario existe, que lo parsee de string a objeto
        if (LSUser) {
            const user = JSON.parse(LSUser);

            // asignar el id de la sociedad a la variable idSociedad
            this.idSociedad = user.iSociedadClav;
        }
    }

    getLogoSrc(): string {
        switch (this.idSociedad) {
            case 1:
                return 'assets/logos/Logo.png';
            case 2:
                return 'assets/logos/qualitas-salud-card.svg';
            case 3:
                return 'assets/logos/qualitas-controladora-card.svg';
            case 4:
                return 'assets/logos/activos-jal-card.svg';
            case 5:
                return 'assets/logos/flekk2.jpg';
            default:
                return 'assets/logos/Logo.png';
        }
    }
}
